<template>
  <div class="bbs">
    <div class="bbs-main">
      <div class="bbs-main-box">
        <groupInfo
          :newWindow="newWindow"
          :userInfo="userInfo"
          :group="group"
          @editGroup="$refs.modalGroupRef.openModal(group)"
        />
      </div>
      <div
        class="content"
        v-if="
          (group.isUnjoinView !== 1 || group.scope1 === 6) && group.state < 8
        "
      >
        <div class="list">
          <a-empty
            style="padding: 80px 0; color: #bbbbbb"
            :image="require('../../assets/image/bbs/empty_join.png')"
            :image-style="{ height: '144px' }"
            :description="$t('bbs.after_join')"
          />
          <!-- 加入后，可浏览精彩内容 -->
        </div>
      </div>
      <div class="content" v-else>
        <typeTab
          hasGroup
          @tabChange="tabChange"
          @search="tabSearch"
          @sort="tabSort"
        />
        <a-spin :spinning="loading">
          <div
            class="list"
            v-infinite-scroll="handleInfiniteOnLoad"
            :infinite-scroll-immediate-check="false"
            :infinite-scroll-disabled="scrollDisabled"
            infinite-scroll-watch-disabled="scrollDisabled"
            :infinite-scroll-distance="50"
          >
            <div
              class="list-item"
              v-for="item in subjectList"
              :key="item.subjectId"
            >
              <posts
                isGroup
                :newWindow="newWindow"
                :data="item"
                @previewVideo="(url) => $refs.previewVideoRef.openModal(url)"
              />
            </div>
            <a-empty
              v-if="!subjectList.length && !loading"
              style="padding: 50px 0"
            />
          </div>
        </a-spin>
        <div class="load-more" v-if="subjectList.length && !loading">
          {{ scrollDisabled ? $t('bbs.no_more') : $t('LB_Doc_Mobile_Load') }}
          <!-- "已经到底了" : "加载中..." -->
        </div>
      </div>
    </div>
    <div class="bbs-sidebar">
      <template v-if="group.state >= 8">
        <sidePost @post="post" v-if="group.isPublish === 1" />
        <template v-else-if="group.isPublish === 2">
          <sidePost
            @post="post"
            :disabled="!(group.masterUid === userInfo.userId)"
          />
        </template>
        <template v-else-if="group.isPublish === 3">
          <sidePost
            @post="post"
            :disabled="
              !(
                group.masterUid === userInfo.userId ||
                group.adminUids.includes(userInfo.userId)
              )
            "
          />
        </template>
      </template>
      <div class="bbs-sidebar-box">
        <sideMaster :data="group" />
      </div>
      <div class="bbs-sidebar-box" v-if="group.showMember === 1">
        <sideMembers :groupId="groupId" :data="group" />
      </div>
    </div>
  </div>

  <modalGroup
    ref="modalGroupRef"
    :coverList="coverList"
    @handleOk="getDetail()"
  />
  <modalPosts
    ref="modalPostsRef"
    :communityConfig="communityConfig"
    :groupId="groupId"
    @handleOk="postEnd(1)"
  />
  <modalQuestion
    ref="modalQuestionRef"
    :communityConfig="communityConfig"
    :groupId="groupId"
    @handleOk="postEnd(2)"
  />
  <modalArticle
    ref="modalArticleRef"
    :communityConfig="communityConfig"
    :groupId="groupId"
    @handleOk="postEnd(3)"
  />
  <previewVideo ref="previewVideoRef" />
</template>

<script>
import { useI18n } from 'vue-i18n';
import {
  reactive,
  toRefs,
  ref,
  computed,
  nextTick,
  getCurrentInstance,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

import ls from "@/utils/local-storage";

import { getCoverList } from "@/api/other";
import {
  getCommunityConfig,
  getTopSubjectList,
  getGroupDeatil,
  getSubjectList,
} from "@/api/bbs";

import tag from "./components/tag.vue";
import groupInfo from "./components/groupInfo.vue";
import topList from "./components/topList.vue";
import typeTab from "./components/typeTab.vue";
import posts from "./components/posts.vue";
import sidePost from "./components/sidePost.vue";
import sideMaster from "./components/sideMaster.vue";
import sideMembers from "./components/sideMembers.vue";
import modalGroup from "./components/modalGroup.vue";
import modalPosts from "./components/modalPosts.vue";
import modalQuestion from "./components/modalQuestion.vue";
import modalArticle from "./components/modalArticle.vue";
import previewVideo from "./components/previewVideo.vue";

import "driver.js/dist/driver.min.css";
import Driver from "driver.js";

export default {
  components: {
    tag,
    groupInfo,
    topList,
    typeTab,
    posts,
    sidePost,
    sideMaster,
    sideMembers,
    modalGroup,
    modalPosts,
    modalQuestion,
    modalArticle,
    previewVideo,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      userInfo: computed(() => store.getters.userInfo),
      newWindow: computed(() => store.getters.companyInfo.useNewWindow == 1),
      loading: false,
      scrollDisabled: true,
      coverList: [],
      groupId: Number(route.query.groupId || 0),
      group: { adminUids: "" },
      communityConfig: {},
      subjectTopList: [],
      subjectParams: {
        groupID: Number(route.query.groupId || 0),
        status: 1,
        pageSize: 10,
        page: 1,
        order: 1,
        subjectType: 0,
      },
      subjectList: [],
    });

    getCoverList(47).then((res) => {
      state.coverList = res.data.sys;
    });

    getCommunityConfig().then((res) => {
      state.communityConfig = res.data;
    });

    const showTips = () => {
      const driver = new Driver({
        prevBtnText: $t('pre'),
        // 上一步
        nextBtnText: $t('next'),
        // 下一步
        doneBtnText: $t('cm_ok'),
        // 好的
        closeBtnText: $t('Pub_Close'),
        // 关闭
      });
      const steps = [
        {
          element: "#step_one",
          popover: {
            title: $t('bbs.group.the_circle_is_created'),
            // 恭喜您，圈子创建好了！
            description: $t('bbs.group.the_circle_is_created_tip'),
            // 在这里，可邀请成员加入您的圈子
            position: "bottom",
          },
        },
        {
          element: "#step_two",
          popover: {
            title: " ",
            description: $t('bbs.group.the_circle_is_created_tip_2'),
            // 作为圈主，请发布本圈子的第一个主题吧
            position: "bottom",
          },
        },
      ];
      driver.defineSteps(steps);
      driver.start();
    };

    const getDetail = () => {
      getGroupDeatil(state.groupId).then((res) => {
        if (res.ret === 0) {
          state.group = res.data;
          if (res.data.state < 8 && res.data.scope1 === 6) {
            proxy.$message.warn($t('bbs.group.contact_owner_join_circle'));
            // 你还未加入该圈子且该圈子是私密圈子，请联系圈主加入圈子！
          }
          if (ls.get("NEWGROUP") == 1) {
            ls.remove("NEWGROUP");
            nextTick(() => {
              setTimeout(() => {
                showTips();
              }, 500);
            });
          }
        } else {
          router.replace({ path: "/bbs" });
        }
      });
    };
    getDetail();

    const getTopData = async () => {
      const { keyword, subjectType, admin } = state.subjectParams;
      const res = await getTopSubjectList({ groupid: state.groupId, keyword, subjectType, admin, });
      if (res.ret === 0) {
        let list = res.data || []
        state.subjectList.unshift(...list);
      }
    };
    const getSubject = () => {
      if (state.subjectParams.page === 1) {
        state.loading = true;
      }
      state.scrollDisabled = true;

      getTopData();

      getSubjectList(state.subjectParams).then((res) => {
        state.loading = false;
        let list = res.data.list || [];
        state.subjectList.push(...list);
        if (state.subjectList.length < res.data.totals) {
          nextTick(() => {
            state.scrollDisabled = false;
          });
        } else {
          state.scrollDisabled = true;
        }
      });
    };
    getSubject();

    const tabChange = (type) => {
      if (type === 4) {
        state.subjectParams.admin = state.group.masterUid;
        state.subjectParams.subjectType = 0;
      } else {
        state.subjectParams.admin = 0;
        state.subjectParams.subjectType = type;
      }
      state.subjectParams.page = 1;
      state.subjectList = [];
      getSubject();
    };

    const tabSearch = (value) => {
      state.subjectParams.keyword = value;
      state.subjectParams.page = 1;
      state.subjectList = [];
      getSubject();
    };

    const tabSort = (value) => {
      state.subjectParams.order = value;
      state.subjectParams.page = 1;
      state.subjectList = [];
      getSubject();
    };

    const modalPostsRef = ref(null);
    const modalQuestionRef = ref(null);
    const modalArticleRef = ref(null);
    const post = (type) => {
      if (type === 1) {
        modalPostsRef.value.openModal();
      } else if (type === 2) {
        modalQuestionRef.value.openModal();
      } else if (type === 3) {
        modalArticleRef.value.openModal();
      }
    };

    const postEnd = (type) => {
      if (
        state.subjectParams.subjectType === type ||
        state.subjectParams.subjectType === 0
      ) {
        state.subjectParams.page = 1;
        state.subjectList = [];
        getSubject();
      }
    };

    const handleInfiniteOnLoad = () => {
      if (!state.scrollDisabled) {
        state.subjectParams.page++;
        getSubject();
      }
    };

    return {
      ...toRefs(state),
      getDetail,
      tabChange,
      tabSearch,
      tabSort,
      modalPostsRef,
      modalQuestionRef,
      modalArticleRef,
      post,
      postEnd,
      handleInfiniteOnLoad,
    };
  },
};
</script>

<style lang="less" scoped>
.bbs {
  .mixinWrap();
  .mixinFlex(space-between);
  padding: 20px 0 80px;
  &-main {
    width: 906px;
    &-box {
      margin-bottom: 24px;
    }
  }
  &-sidebar {
    width: 270px;
    position: relative;
    &-box {
      margin-bottom: 24px;
    }
  }
  .banner {
    width: 906px;
    height: 256px;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 24px;
  }
  .content {
    width: 906px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    .list {
      padding: 0 30px 24px;
      min-height: 500px;
      &-item {
        padding: 30px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      }
    }
    .load-more {
      text-align: center;
      font-size: 14px;
      color: #999;
      line-height: 20px;
      padding: 0 0 24px 0;
    }
  }
}
</style>
